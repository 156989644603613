<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h4 pb-5 primary--text">
      แก้ไขข้อมูลสินค้า พ.ร.บ.
    </v-card-title>
    <v-card-text class="popup-background">
      <v-row justify="center">
        <v-col cols="11">
          <div class="px-10 pt-5">
            <v-col cols="4" class="pa-0">
              <v-text-field
                v-model="name"
                disabled
                label="พ.ร.บ. ของบริษัท"
                placeholder="ชื่อหมวดหมู่ย่อย"
                outlined
                dense
                background-color="white"
              />
            </v-col>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="11">
          <div class="px-10">
            <div class="mb-5">
              <v-btn color="success" outlined small @click="changedStatus(true)"
                >เปิดใช้งาน</v-btn
              >
              <v-btn
                color="error"
                outlined
                small
                class="ml-2"
                @click="changedStatus(false)"
                >ปิดใช้งาน</v-btn
              >
              <v-btn
                color="primary"
                outlined
                small
                class="ml-2 float-right "
                @click="activeBikeProduct()"
                >เปิดใช้งานรถ จยย.</v-btn
              >
              <v-btn
                color="primary"
                outlined
                small
                class="ml-2 float-right"
                @click="activeCarProduct()"
                >เปิดใช้งานรถยนต์</v-btn
              >
            </div>

            <v-data-table
              class="elevation-1 row-pointer"
              :headers="headers"
              :items="form.product"
              :search="search"
              :loading="loading"
              sort-by="calories"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              hide-default-footer
              show-select
              item-key="_id"
              v-model="selected"
            >
              <template v-slot:[`item.is_available`]="{ item }">
                <menu-ship
                  v-model="item.is_available"
                  path="third_insurance/changed_third_insurance_status"
                  :params="{ third_insurance_id: item._id }"
                  @success="$alertSuccess(), getData(id)"
                />
              </template>
              <template v-slot:[`item.name`]="{ item }">
                {{ item.sku_code }} {{ item.sku_detail }}
              </template>
              <template v-slot:[`item.price`]="{ item }">
                {{ item.price | numeral('0,0.00') }}
              </template>
              <template v-slot:[`item.vat_price`]="{ item }">
                {{ item.vat_price | numeral('0,0.00') }}
              </template>
              <template v-slot:[`item.stamp_price`]="{ item }">
                {{ item.stamp_price | numeral('0,0.00') }}
              </template>
              <template v-slot:[`item.total_price`]="{ item }">
                {{ item.total_price | numeral('0,0.00') }}
              </template>

              <!-- <template v-slot:footer>
                <table-pagination :page.sync="page" :itemsPerPage.sync="itemsPerPage" :length="Math.ceil(pageCount/itemsPerPage)"/>
              </template> -->
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5">
      <div  class="ml-10">
        <v-btn
          color="success"
          class="ml-16"
          outlined
          small
          @click="changedStatus(true)"
          >เปิดใช้งาน</v-btn
        >
        <v-btn
          color="error"
          outlined
          small
          class="ml-2"
          @click="changedStatus(false)"
          >ปิดใช้งาน</v-btn
        >
      </div>
      <v-spacer />
      <v-btn outlined class="pr-5 mr-10" color="grey" @click="close()">
        ปิด
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    id: {
      type: [String, Number],
      default: null
    },
    name: {
      type: [String],
      default: null
    },
    changeFormCount: {
      type: [Function]
    }
  },
  watch: {
    id: function(newVal, oldVal) {
      this.getData(newVal);
    }
  },
  mounted() {
    this.getData(this.id);
  },
  computed: {
    isUpdate() {
      return this.id;
    }
  },
  data() {
    return {
      search: '',
      selected: [],
      loading: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 200,
      headers: [
        { text: 'ชื่อสินค้า', value: 'name' },
        { text: 'เบี้ยสุทธิ', value: 'price' },
        { text: 'ภาษี', value: 'vat_price' },
        { text: 'อากร', value: 'stamp_price' },
        { text: 'เบี้ยรวม', value: 'total_price' },
        { text: 'สถานะ', value: 'is_available', align: 'center' }
      ],
      list: {},
      form: {
        product: []
      }
    };
  },
  methods: {
    save() {
      this.loading = true;
      this.resetForm();
    },
    close() {
      this.page = 1;
      this.$emit('onClose');
    },
    resetForm() {
      this.form = {};
    },
    async getData(id) {
      if (id) {
        let body = {
          token: this.$jwt.sign({ sub_category_id: id }, this.$privateKey, {
            noTimestamp: true
          })
        };
        await this.$axios
          .post(`${this.$baseUrl}/third_insurance/get_third_insurance`, body)
          .then(async res => {
            this.form = { ...res.result };
            this.pageCount = res.result.length;
            this.form.product = res.result;
            let totalAvailable = res.result.filter(
              insurance => insurance.is_available == true
            ).length;
            this.changeFormCount(totalAvailable);
          })
          .catch(err => {
            console.log('err', err);
          });
      } else {
        this.resetForm();
      }
      this.loading = false;
    },
    async activeBikeProduct() {
      if (this.id) {
        let body = {
          token: this.$jwt.sign(
            { sub_category_id: this.id },
            this.$privateKey,
            { noTimestamp: true }
          )
        };
        await this.$axios
          .post(
            `${this.$baseUrl}/third_insurance/changed_bike_third_insurance_status`,
            body
          )
          .then(async res => {
            this.getData(this.id);
          })
          .catch(err => {
            console.log('err', err);
          });
      } else {
        this.resetForm();
      }
      this.loading = false;
    },
    async activeCarProduct() {
      if (this.id) {
        let body = {
          token: this.$jwt.sign(
            { sub_category_id: this.id },
            this.$privateKey,
            { noTimestamp: true }
          )
        };
        await this.$axios
          .post(
            `${this.$baseUrl}/third_insurance/changed_car_third_insurance_status`,
            body
          )
          .then(async res => {
            this.getData(this.id);
          })
          .catch(err => {
            console.log('err', err);
          });
      } else {
        this.resetForm();
      }
      this.loading = false;
    },
    async activeBikeProduct() {
      if (this.id) {
        let body = {
          token: this.$jwt.sign(
            { sub_category_id: this.id },
            this.$privateKey,
            { noTimestamp: true }
          )
        };
        await this.$axios
          .post(
            `${this.$baseUrl}/third_insurance/changed_bike_third_insurance_status`,
            body
          )
          .then(async res => {
            this.getData(this.id);
          })
          .catch(err => {
            console.log('err', err);
          });
      } else {
        this.resetForm();
      }
      this.loading = false;
    },
    async activeCarProduct() {
      if (this.id) {
        let body = {
          token: this.$jwt.sign(
            { sub_category_id: this.id },
            this.$privateKey,
            { noTimestamp: true }
          )
        };
        await this.$axios
          .post(
            `${this.$baseUrl}/third_insurance/changed_car_third_insurance_status`,
            body
          )
          .then(async res => {
            this.getData(this.id);
          })
          .catch(err => {
            console.log('err', err);
          });
      } else {
        this.resetForm();
      }
      this.loading = false;
    },
    changedStatus(status) {
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            let third_insurance_id = [];
            this.selected.forEach(element => {
              third_insurance_id.push(element._id);
            });
            let filter = {
              third_insurance_id: third_insurance_id,
              is_available: status
            };
            let body = {
              token: this.$jwt.sign(filter, this.$privateKey, {
                noTimestamp: true
              })
            };

            await this.$axios
              .post(
                `${this.$baseUrl}/third_insurance/changed_third_insurance_status `,
                body
              )
              .then(res => {
                this.selected = [];
                this.$alertSuccess({ title: res.status });
                this.getData(this.id);
              })
              .catch(err => {
                this.$alertServerError({ title: err.error_message });
              });
          }
        }
      );
    }
  }
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
