<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h4 pb-10 primary--text"
      >{{ isUpdate ? 'แก้ไข' : 'สร้าง' }}ข้อมูลสินค้าประกันภัย</v-card-title
    >
    <v-card-text class="popup-background">
      <v-row justify="center">
        <v-col cols="5">
          <!-- <v-text-field
            name="product_code"
            label="รหัสสินค้า"
            outlined
            dense
            background-color="white"
            v-model="form.product_code"
          /> -->
          <v-text-field
            class="mt-2"
            name="name"
            label="ชื่อสินค้า"
            outlined
            dense
            background-color="white"
            v-model="form.name"
            :error-messages="$v.form.name.$error ? $error_text : ''"
          />
          <v-select
            name="type"
            label="ประเภท"
            outlined
            dense
            background-color="white"
            :items="list.type"
            item-value="value"
            item-text="name"
            v-model="form.type"
            :error-messages="$v.form.type.$error ? $error_text : ''"
          ></v-select>
          <v-textarea
            name="detail"
            label="รายละเอียดสินค้า"
            outlined
            dense
            background-color="white"
            v-model="form.detail"
          />
        </v-col>
        <v-col cols="4" offset="1">
          <!-- <span class="mr-5">สถานะ</span>
          <menu-ship
            v-model="form.is_available"
            :disabled="!isUpdate"
            path="insurance/changed_insurance_status"
            :params="{ insurance_id: form._id }"
            @success="
              val => {
                $alertSuccess(), (form.is_available = val), $emit('success');
              }
            "
          />
          <v-textarea
            name=""
            label="หมายเหตุ"
            outlined
            dense
            background-color="white"
            v-model="form.note"
          /> -->
          <v-text-field
          class="mt-2"
            disabled
            name="price"
            label="เบียประกันสุทธิ"
            outlined
            dense
            background-color="white"
            :error-messages="$v.form.price.$error ? $error_text : ''"
            v-model.number="form.price"
            @change="val => handleInsurancePrice('price', val)"
          />
          <v-text-field
            name="vat_price"
            label="ภาษีมูลค่าเพิ่ม"
            outlined
            dense
            background-color="white"
            v-model.number="form.vat_price"
            :error-messages="$v.form.vat_price.$error ? $error_text : ''"
            disabled
          />
          <v-text-field
            name="stamp_price"
            label="อากรแสตมป์"
            outlined
            dense
            background-color="white"
            v-model.number="form.stamp_price"
            :error-messages="$v.form.stamp_price.$error ? $error_text : ''"
            disabled
          />
          <v-text-field
            name="total_price"
            label="เบี้ยรวม"
            outlined
            dense
            background-color="white"
            v-model.number="form.total_price"
            @change="val => handleInsurancePrice('total_price', val)"
            :error-messages="$v.form.total_price.$error ? $error_text : ''"
          />
          <!-- <div>
            แจ้งเตือนเมื่อเลือกสินค้านี้
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" small v-bind="attrs" v-on="on">mdi-information</v-icon>
              </template>
              <v-sheet class="pa-3">info</v-sheet>
            </v-menu> 
          </div>
          <v-radio-group class="mt-0" row v-model="form.message_alert">
            <v-radio name="" label="ไม่แจ้งเตือน" :value="false"></v-radio>
              <v-spacer/> 
            <v-radio name="" label="แจ้งเตือน" :value="true"></v-radio>
          </v-radio-group> -->

          <v-textarea
            v-if="form.message_alert"
            name=""
            label="ข้อความแจ้งเตือน"
            outlined
            dense
            background-color="white"
            v-model="form.message_on_alert"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer />
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()"
        >ปิด</v-btn
      >
      <v-btn
        class="px-10 mr-15"
        color="primary"
        @click="save()"
        :loading="loading"
        :disabled="loading"
        >บันทึก</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { required, decimal, helpers } from 'vuelidate/lib/validators';

export default {
  props: {
    id: {
      type: [String, Number],
      default: null
    },
    dialogUpdate: {
      type: [Boolean]
    }
  },
  validations() {
    return {
      form: {
        name: { required },
        type: { required },
        price: { decimal },
        vat_price: { decimal },
        stamp_price: { decimal },
        total_price: {
          required,
          decimal
        }
      }
    };
  },
  watch: {
    dialogUpdate(value) {
      if (value) {
        this.getData(this.id);
      }
    }
  },
  mounted() {
    this.getData(this.id);
  },
  created() {
    this.form.sub_category_id = this.$route.params.id;
  },
  data() {
    return {
      loading: false,
      form: {
        sub_category_id: '',
        product_code: '',
        name: '',
        type: '',
        detail: '',
        price: 0,
        vat_price: 0,
        total_price: 0,
        stamp_price: 0,
        note: '',
        message_on_select: '',
        message_alert: false,
        is_available: true
      },
      list: {
        type: [
          { value: 0, name: 'ป.1' },
          { value: 1, name: 'ป.2' },
          { value: 2, name: 'ป.2+' },
          { value: 3, name: 'ป.3' },
          { value: 4, name: 'ป.3+' },
          { value: 5, name: 'ป.4' },
          { value: 6, name: 'อื่น' }
        ]
      }
    };
  },
  methods: {
    close() {
      this.resetForm();
      this.$emit('onClose');
    },
    resetForm() {
      this.form = {
        sub_category_id: this.$route.params.id,
        product_code: '',
        name: '',
        type: '',
        detail: '',
        price: 0,
        vat_price: 0,
        total_price: 0,
        stamp_price: 0,
        note: '',
        message_on_select: '',
        message_alert: false,
        is_available: true
      };
    },
    async getData(id) {
      this.$v.form.$reset();
      if (id) {
        let body = {
          token: this.$jwt.sign({ insurance_id: id }, this.$privateKey, {
            noTimestamp: true
          })
        };
        await this.$axios
          .post(`${this.$baseUrl}/insurance/get_by_id`, body)
          .then(async res => {
            this.form = { ...res.result };
          })
          .catch(err => {
            console.log('err', err);
          });
      } else {
        this.resetForm();
      }
      this.loading = false;
    },
    handleInsurancePrice(key = 'price', val) {
      var price = this.form.price;
      var price_no_vat = 0;
      var vat_price = this.form.vat_price;
      var stamp_price = this.form.stamp_price;
      var total_price = this.form.total_price;

      if (key == 'price') {
        stamp_price = Math.ceil(price - price / 1.004);
        price_no_vat = price + stamp_price;
        vat_price = price_no_vat * (7 / 100);
        total_price = price_no_vat + vat_price;
      } else if (key == 'total_price') {
        price_no_vat = total_price / 1.07;
        vat_price = total_price - price_no_vat;
        stamp_price = Math.ceil(price_no_vat - price_no_vat / 1.004);
        price = price_no_vat - stamp_price;
      }

      this.form.price = parseFloat(parseFloat(price).toFixed(2));
      this.form.vat_price = parseFloat(parseFloat(vat_price).toFixed(2));
      this.form.stamp_price = parseFloat(parseFloat(stamp_price).toFixed(2));
      this.form.total_price = parseFloat(parseFloat(total_price).toFixed(2));
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;

      this.loading = true;
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            let form = this.form;
            delete form.price;
            delete form.vat_price;
            delete form.stamp_price;
            form.total_price = parseFloat(form.total_price);

            let subPath = 'add_insurance';
            if (this.isUpdate) {
              subPath = 'edit_insurance';
              form = { insurance_id: form._id, ...form };
              delete form._id;
            }
            let body = {
              token: this.$jwt.sign(form, this.$privateKey, {
                noTimestamp: true
              })
            };

            await this.$axios
              .post(`${this.$baseUrl}/insurance/${subPath}`, body)
              .then(res => {
                this.$alertSuccess({ title: res.status });
                this.$emit('success');
                this.$emit('onClose');
                this.$v.form.$reset();
                this.resetForm();
              })
              .catch(err => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          }
          this.loading = false;
        }
      );
    }
  },
  computed: {
    isUpdate() {
      return this.id;
    }
  }
};
</script>
