<template>
  <div class="px-10">
    <div class="text-h4 mb-5 primary--text">
      {{ isUpdate ? 'แก้ไข' : 'สร้าง' }}หมวดหมู่ย่อย สินค้า พ.ร.บ. และประกันภัย
    </div>

    <v-row>
      <v-col cols="12">
        <div class="text-h5 dark--text">ข้อมูล</div>
      </v-col>
      <v-col cols="4" class="pl-15">
        <v-text-field
          class="mt-2"
          name="name"
          v-model="form.third_insurance.name"
          label="ชื่อ พ.ร.บ. / ประกันภัย"
          outlined
          dense
          :error-messages="
            $v.form.third_insurance.name.$error ? $error_text : ''
          "
        />
        <v-text-field
          name="broker"
          v-model="form.third_insurance.broker"
          label="นายหน้า"
          outlined
          dense
        />
        <!-- v-model="$store.state.selected_branch._id" -->
        <!-- v-model="form.third_insurance.branch_id" -->
        <!-- :value="$store.state.selected_branch._id" -->
        <!-- :v-model="showBranch()" -->
        <!-- v-model="valueBranch" -->
        <!-- :items="itemsBranch"  -->
        <v-select
          v-model="form.third_insurance.branch_id"
          :items="$store.state.list.branchs"
          :search-input.sync="$store.state.selected_branch._id"
          :error-messages="
            $v.form.third_insurance.branch_id.$error ? $error_text : ''
          "
          item-value="_id"
          item-text="branch_name"
          label="สาขา (เลือกเพียง 1 สาขาเท่านั้น)"
          dense
          outlined
          disabled
          name="branch"
          class="mt-3"
          multiple
        >
          <template v-slot:selection="{ item }">
            <v-chip>
              <span>{{ item.branch_name }}</span>
            </v-chip>
          </template>
        </v-select>
      </v-col>
      <v-col cols="4" offset="1">
        <span class="mr-5">สถานะ</span>
        <menu-ship
          v-model="form.third_insurance.is_available"
          :disabled="!isUpdate"
          path="sub_category/changed_insurance_sub_category_status"
          :params="{ sub_category_id: form.third_insurance._id }"
          @success="
            (val) => {
              $alertSuccess(), handleStatus(val);
            }
          "
        />
        <br />
        <br />
        <v-radio-group row dense v-model="form.third_insurance.is_add1day">
          <div class="mr-5">
            +1 วัน เมื่อปรับรอบพรบ. เช่น บริษัท วิริยะ สินมั่นคง เป็นต้น
          </div>
          <v-radio label="ใช่" :value="true"></v-radio>
          <v-radio label="ไม่ใช่" :value="false"></v-radio>
          <v-spacer />
        </v-radio-group>
        <div class="mr-5 mt-10">
          <v-btn class="px-8 mr-6" color="grey" outlined small @click="close()"
            >ปิด</v-btn
          >
          <v-btn
            class="px-8"
            color="primary"
            @click="save()"
            :loading="loading"
            :disabled="loading"
            small
            >บันทึกข้อมูล</v-btn
          >
        </div>
      </v-col>
    </v-row>

    <v-divider class="mt-5 mb-10" />

    <v-row>
      <v-col cols="12">
        <div class="text-h5 dark--text">ตั้งค่า พ.ร.บ.</div>
      </v-col>
      <v-col cols="4" class="pl-15">
        <v-text-field
          name="tel_number"
          v-model="form.third_insurance.tel_number"
          label="เบอร์ติดต่อ"
          outlined
          dense
        />
        <v-text-field
          name="merging_name"
          v-model="form.third_insurance.merging_name"
          label="ชื่อสำหรับรวมสินค้า"
          outlined
          dense
        />
        <v-textarea
          name="note"
          v-model="form.third_insurance.note"
          label="หมายเหตุ"
          outlined
          dense
        />
      </v-col>
      <v-col cols="4" offset="1">
        <v-row>
          <v-col cols="7">
            <v-text-field
              name="count"
              v-model="form.third_insurance.count"
              label="พ.ร.บ. ที่เปิดใช้งาน"
              outlined
              dense
              readonly
              suffix="รหัส"
            />
          </v-col>
          <v-col cols="5">
            <v-btn
              color="primary"
              outlined
              @click="dialogAct = true"
              :disabled="!isUpdate"
              >แก้ไขพ.ร.บ.<v-icon right>mdi-pencil</v-icon></v-btn
            >
          </v-col>
        </v-row>

        <!-- <div>
          ทำล่วงหน้าไม่เกิน 90 วัน
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on"
                >mdi-information</v-icon
              >
            </template>
            <v-sheet class="pa-3">info</v-sheet>
          </v-menu>
        </div>
        <v-radio-group
          class="mt-0 mb-5"
          row
          v-model="form.third_insurance.pc1_setting.day_advance_90"
        >
          <v-radio label="ไม่แจ้งเตือน" :value="false"></v-radio>
          <v-radio label="แจ้งเตือน" :value="true"></v-radio>
        </v-radio-group> -->

        <!-- <div>
          ทำเต็มปีเท่านั้น
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on"
                >mdi-information</v-icon
              >
            </template>
            <v-sheet class="pa-3">info</v-sheet>
          </v-menu>
        </div>
        <v-radio-group
          class="mt-0 mb-5"
          row
          v-model="form.third_insurance.pc1_setting.only_full_year"
        >
          <v-radio label="ไม่แจ้งเตือน" :value="false"></v-radio>
          <v-radio label="แจ้งเตือน" :value="true"></v-radio>
        </v-radio-group>

        <div>
          คุ้มครองอย่างน้อย 180 วัน
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on"
                >mdi-information</v-icon
              >
            </template>
            <v-sheet class="pa-3">info</v-sheet>
          </v-menu>
        </div>
        <v-radio-group
          class="mt-0 mb-5"
          row
          v-model="form.third_insurance.pc1_setting.least_coverage_180"
        >
          <v-radio label="ไม่แจ้งเตือน" :value="false"></v-radio>
          <v-radio label="แจ้งเตือน" :value="true"></v-radio>
        </v-radio-group>

        <div>
          แจ้งเตือนเสริมเมื่อเลือกพรบ.นี้
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on"
                >mdi-information</v-icon
              >
            </template>
            <v-sheet class="pa-3">info</v-sheet>
          </v-menu>
        </div>
        <v-radio-group
          class="mt-0"
          row
          v-model="form.third_insurance.pc1_setting.message_alert"
        >
          <v-radio label="ไม่แจ้งเตือน" :value="false"></v-radio>
          <v-radio label="แจ้งเตือน" :value="true"></v-radio>
        </v-radio-group> -->
        <!-- <v-textarea
          name="message_on_select"
          v-model="form.third_insurance.pc1_setting.message_on_select"
          label="ข้อความแจ้งเตือน"
          outlined
          dense
          rows="3"
      /> -->
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col cols="4" class="pl-15 pb-0 mb-0">
        <v-radio-group
          row
          dense
          v-model="form.third_insurance.manual_available"
        >
          <div class="mr-10">คู่มือ พ.ร.บ.</div>
          <v-radio label="เปิด" :value="true"></v-radio>
          <v-radio label="ปิด" :value="false"></v-radio>
          <v-spacer />
        </v-radio-group>
      </v-col>
      <v-col cols="8" class="pb-0 mb-0"></v-col>
    <v-row v-if="form.third_insurance.manual_available"> 
      <v-col cols="4" class="pl-15">
        <v-text-field
          name="manual_user_name"
          v-model="form.third_insurance.manual_setting.manual_user_name"
          label="ชื่อผู้ใช้"
          outlined
          dense
          autocomplete="off"
        />
        <v-text-field
          name="manual_password"
          v-model="form.third_insurance.manual_setting.manual_password"
          label="รหัสผ่าน"
          outlined
          dense
          autocomplete="off"
        />
        <v-text-field
          name="manual_link"
          v-model="form.third_insurance.manual_setting.manual_link"
          label="ลิงค์ที่ทำ พ.ร.บ."
          outlined
          dense
        />
        <v-text-field
          name="manual_paper"
          v-model="form.third_insurance.manual_setting.manual_paper"
          label="กระดาษที่ใช้ปริ้น"
          outlined
          dense
        />
        <v-textarea
          name="manual_process"
          v-model="form.third_insurance.manual_setting.manual_process"
          label="การทำ พ.ร.บ."
          outlined
          dense
        />
      </v-col>
      <v-col offset="1" cols="4">
        <v-textarea
          name="manual_edited"
          v-model="form.third_insurance.manual_setting.manual_edited"
          label="การแก้ไข พ.ร.บ."
          outlined
          dense
        />
        <v-textarea
          name="manual_cancel"
          v-model="form.third_insurance.manual_setting.manual_cancel"
          label="การยกเลิก พ.ร.บ."
          outlined
          dense
        />
        <v-textarea
          name="manual_note"
          v-model="form.third_insurance.manual_setting.manual_note"
          label="หมายเหตุคู่มือ พ.ร.บ."
          outlined
          dense
        />
      </v-col>
    </v-row>
    </v-row> -->

    <v-divider class="mt-5 mb-10" />

    <v-row>
      <v-col cols="12">
        <div class="text-h5 dark--text">ตั้งค่าประกันภัย</div>
      </v-col>
      <v-col cols="4" class="pl-15">
        <v-text-field
          name="tel_number"
          v-model="form.insurance.tel_number"
          label="เบอร์ติดต่อ"
          outlined
          dense
        />
        <v-text-field
          name="merging_name"
          v-model="form.insurance.merging_name"
          label="ชื่อสำหรับรวมสินค้า"
          outlined
          dense
        />
        <v-textarea
          name="note"
          v-model="form.insurance.note"
          label="หมายเหตุ"
          outlined
          dense
        />
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col cols="4" class="pl-15 pb-0 mb-0">
        <v-radio-group row dense v-model="form.insurance.manual_available">
          <div class="mr-10">คู่มือประกันภัย</div>
          <v-radio label="เปิด" :value="true"></v-radio>
          <v-radio label="ปิด" :value="false"></v-radio>
          <v-spacer />
        </v-radio-group>
      </v-col>
      <v-col cols="8" class="pb-0 mb-0"></v-col>
      
      <v-row v-if="form.insurance.manual_available"> 
      <v-col cols="4" class="pl-15">
        <v-text-field
          name="manual_user_name"
          v-model="form.insurance.manual_setting.manual_user_name"
          label="ชื่อผู้ใช้"
          outlined
          dense
          autocomplete="off"
        />
        <v-text-field
          name="manual_password"
          v-model="form.insurance.manual_setting.manual_password"
          label="รหัสผ่าน"
          outlined
          dense
          autocomplete="off"
        />
        <v-text-field
          name="manual_link"
          v-model="form.insurance.manual_setting.manual_link"
          label="ลิงค์ที่ทำประกันภัย"
          outlined
          dense
        />
        <v-text-field
          name="manual_paper"
          v-model="form.insurance.manual_setting.manual_paper"
          label="กระดาษที่ใช้ปริ้น"
          outlined
          dense
        />
        <v-textarea
          name="manual_process"
          v-model="form.insurance.manual_setting.manual_process"
          label="การทำประกันภัย"
          outlined
          dense
        />
      </v-col>
      <v-col offset="1" cols="4">
        <v-textarea
          name="manual_edited"
          v-model="form.insurance.manual_setting.manual_edited"
          label="การแก้ไขประกันภัย"
          outlined
          dense
        />
        <v-textarea
          name="manual_cancel"
          v-model="form.insurance.manual_setting.manual_cancel"
          label="การยกเลิกประกันภัย"
          outlined
          dense
        />
        <v-textarea
          name="manual_note"
          v-model="form.insurance.manual_setting.manual_note"
          label="หมายเหตุคู่มือประกันภัย"
          outlined
          dense
        />
      </v-col>
      </v-row>
    </v-row> -->
    <v-divider class="mt-5 mb-10" />
    <v-toolbar dense flat class="mb-5">
      <v-col cols="3">
        <div class="text-h5 dark--text">ตารางสินค้าประกันภัย</div>
      </v-col>
      <v-spacer></v-spacer>
      <!--<v-btn color="success" :outlined="filter.active != true" small :disabled="!isUpdate" @click="handleGetInsurance(true)">เปิดใช้งาน</v-btn>
      <v-btn color="error" :outlined="filter.active != false" small class="ml-2" :disabled="!isUpdate" @click="handleGetInsurance(false)">ปิดใช้งาน</v-btn>-->
      <v-btn
        color="success"
        outlined
        small
        class="ml-5"
        :disabled="!selected.length"
        @click="changedStatus(true)"
      >
        เปิดใช้งาน
      </v-btn>
      <v-btn
        color="error"
        outlined
        small
        class="ml-2"
        :disabled="!selected.length"
        @click="changedStatus(false)"
        >ปิดใช้งาน</v-btn
      >
      <v-btn
        color="primary"
        outlined
        small
        class="ml-5"
        @click="(insurance_id = 0), (dialogUpdate = true)"
        :disabled="!isUpdate"
        ><v-icon left small>mdi-plus</v-icon> เพิ่มสินค้า ประกันภัย</v-btn
      >
    </v-toolbar>

    <v-data-table
      class="elevation-2 row-pointer"
      :headers="headers"
      :items="list.insurance"
      :loading="loading"
      sort-by="code"
      :page.sync="filter.page"
      :items-per-page="filter.itemsPerPage"
      @page-count="pageCount = $event"
      hide-default-footer
      show-select
      item-key="_id"
      v-model="selected"
      @click:row="editInsurance"
    >
      <template v-slot:[`item.is_available`]="{ item }">
        <menu-ship
          v-model="item.is_available"
          path="insurance/changed_insurance_status"
          :params="{ insurance_id: item._id }"
          @success="$alertSuccess(), getData()"
        />
      </template>
      <template v-slot:[`item.total_price`]="{ item }">
        {{ item.total_price | numeral('0,0.00') }}
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{
          list.type.filter((el) => {
            return el.value == item.type;
          })[0].name
        }}
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item dense link @click="editInsurance(item)">
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="remove(item)">
                <v-list-item-title>ลบ</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-row>
      <v-col class="text-right py-10">
        <v-btn class="px-10 mr-15" color="grey" outlined @click="close()"
          >ปิด</v-btn
        >
        <v-btn
          class="px-10"
          color="primary"
          @click="save()"
          :loading="loading"
          :disabled="loading"
          >บันทึกข้อมูล</v-btn
        >
      </v-col>
    </v-row>

    <v-dialog persistent scrollable max-width="1200" v-model="dialogUpdate">
      <ProductDetail
        :id="insurance_id"
        :dialogUpdate="dialogUpdate"
        @onClose="dialogUpdate = false"
        @success="getInsurance()"
      />
    </v-dialog>

    <v-dialog persistent scrollable max-width="1200" v-model="dialogAct">
      <ProductDetailAct
        :id="sub_category_id"
        @onClose="dialogAct = false"
        :name="form.third_insurance.name"
        :changeFormCount="changeFormCount"
      />
    </v-dialog>
  </div>
</template>

<script>
import ProductDetail from '@/views/pages/product/insurance/ProductDetail.vue';
import ProductDetailAct from '@/views/pages/product/insurance/ProductDetailAct.vue';
import {
  required,
  integer,
  minValue,
  minLength,
  numeric,
  maxLength,
} from 'vuelidate/lib/validators';

export default {
  components: {
    ProductDetail,
    ProductDetailAct,
  },
  validations: function () {
    return {
      form: {
        third_insurance: {
          name: { required },
          branch_id: { required },
          count: {
            required,
            integer,
            minValue: minValue(
              this.form.third_insurance.category_id == 'PC1' ? 0 : 1
            ),
          },
        },
        insurance: {
          name: { required },
          branch_id: {},
          //tel_number: { numeric, minLength: minLength(9), maxLength: maxLength(10) },
        },
      },
    };
  },
  data: () => ({
    selected: [],
    status: 0,
    form: {
      third_insurance: {
        is_add1day: false,
        sub_category_id: 0,
        category_id: 'PC1',
        branch_id: [],
        name: '',
        broker: '',
        tel_number: '',
        count: 0,
        merging_name: '',
        pc1_setting: {
          day_advance_90: false,
          only_full_year: false,
          least_coverage_180: false,
          message_on_select: '',
          message_alert: false,
        },
        note: '',
        manual_available: false,
        manual_setting: {
          manual_user_name: '',
          manual_password: '',
          manual_link: '',
          manual_paper: '',
          manual_process: '',
          manual_edited: '',
          manual_cancel: '',
          manual_note: '',
        },
        is_available: true,
      },
      insurance: {
        sub_category_id: 0,
        category_id: 'PC2',
        branch_id: [],
        name: '',
        broker: '',
        tel_number: '',
        merging_name: '',
        pc1_setting: {
          day_advance_90: false,
          only_full_year: false,
          least_coverage_180: false,
          message_on_select: '',
          message_alert: false,
        },
        note: '',
        manual_available: false,
        manual_setting: {
          manual_user_name: '',
          manual_password: '',
          manual_link: '',
          manual_paper: '',
          manual_process: '',
          manual_edited: '',
          manual_cancel: '',
          manual_note: '',
        },
        is_available: true,
      },
    },
    insurance_id: 0,
    search: '',

    loading: false,
    headers: [
      // {
      //   text: 'รหัสสินค้า',
      //   align: 'start',
      //   sortable: false,
      //   value: 'product_code',
      //   filterable: false
      // },
      { text: 'ชื่อสินค้า', value: 'name' },
      { text: 'รายละเอียด', value: 'detail', filterable: false },
      { text: 'ประเภท', value: 'type', filterable: false },
      { text: 'ราคา', value: 'total_price', filterable: false },
      { text: 'สถานะ', value: 'is_available', filterable: false },
      { text: '', value: 'actions', sortable: false, filterable: false },
    ],
    dialogUpdate: false,
    dialogData: null,
    dialogAct: false,
    list: {
      branchs: [],
      insurance: [],
      type: [
        { value: 0, name: 'ป.1' },
        { value: 1, name: 'ป.2' },
        { value: 2, name: 'ป.2+' },
        { value: 3, name: 'ป.3' },
        { value: 4, name: 'ป.3+' },
        { value: 5, name: 'ป.4' },
        { value: 6, name: 'อื่น' },
      ],
    },
    filter: {
      active: 'all',
      item_per_page: 20,
      page: 1,
      start_with: '',
      branch_id: 0,
      sub_category_id: 0,
    },
    sub_category_id: 0,
  }),

  created() {
    this.sub_category_id = this.$route.params.id;
    if (!this.isUpdate) {
      this.showBranchSelected();
    }
    if (this.isUpdate) {
      this.getData();
    }
  },
  methods: {
    changedStatus(status) {
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            let insurance_id = [];
            this.selected.forEach((element) => {
              insurance_id.push(element._id);
            });

            let fill = {
              insurance_id: insurance_id,
              is_available: status,
            };
            let body = {
              token: this.$jwt.sign(fill, this.$privateKey, {
                noTimestamp: true,
              }),
            };
            await this.$axios
              .post(`${this.$baseUrl}/insurance/changed_insurance_status`, body)
              .then((res) => {
                this.selected = [];
                this.$alertSuccess({ title: res.status });
                this.getData();
              })
              .catch((err) => {
                this.$alertServerError({ title: err.error_message });
              });
          }
        }
      );
    },
    async editInsurance(item) {
      this.insurance_id = item._id;
      this.dialogUpdate = true;
    },
    showBranchSelected() {
      let body = {
        token: this.$jwt.sign({}, this.$privateKey, { noTimestamp: true }),
      };
      this.$axios
        .post(`${this.$baseUrl}/branch/get_branch_list`, body)
        .then(async (res) => {
          this.list.branchs = res.result;
          this.form.third_insurance.branch_id = [
            this.$store.state.selected_branch._id,
          ];
          console.log(this.form.third_insurance.branch_id);
        });
    },
    changeFormCount(total) {
      this.form.third_insurance.count = total;
    },
    getBranchs() {
      let body = {
        token: this.$jwt.sign({}, this.$privateKey, { noTimestamp: true }),
      };
      this.$axios
        .post(`${this.$baseUrl}/branch/get_branch_list`, body)
        .then(async (res) => {
          this.list.branchs = res.result;
          console.log('sdgasgasdfgsdgsdg');
        });
    },
    async getData() {
      let body = {
        token: this.$jwt.sign(
          { sub_category_id: this.$route.params.id },
          this.$privateKey,
          { noTimestamp: true }
        ),
      };
      await this.$axios
        .post(`${this.$baseUrl}/sub_category/get_by_id`, body)
        .then(async (res) => {
          this.form = { ...res.result };
          this.getInsurance();
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    handleGetInsurance(available) {
      this.filter.page = 1;
      if (
        (this.filter.active == true && available) ||
        (this.filter.active == false && !available)
      )
        this.filter.active = 'all';
      else this.filter.active = available;

      this.getInsurance();
    },
    async getInsurance() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(
          { sub_category_id: this.$route.params.id },
          this.$privateKey,
          { noTimestamp: true }
        ),
      };
      await this.$axios
        .post(`${this.$baseUrl}/insurance/get_insurance`, body)
        .then(async (res) => {
          let data = res.result;
          let listFiltered = data;
          if (this.filter.active != 'all') {
            listFiltered = await data.filter((el) => {
              return el.is_available == this.filter.active;
            });
          }
          this.list.insurance = listFiltered;
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    handleStatus(val) {
      this.form.third_insurance.is_available = val;
      this.form.insurance.is_available = val;
    },
    save(isClose = false) {
      this.form.insurance.name = this.form.third_insurance.name;
      this.form.insurance.branch_id = this.form.third_insurance.branch_id;
      this.form.insurance.broker = this.form.third_insurance.broker;
      this.form.insurance.is_add1day = this.form.third_insurance.is_add1day;

      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;

      this.loading = true;
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            let subPath = 'add_insurance_sub_category';
            delete this.form.third_insurance.count;
            if (this.isUpdate) {
              subPath = 'edit_insurance_sub_category';
              this.form.third_insurance.sub_category_id =
                this.form.third_insurance._id;
              this.form.insurance.sub_category_id = this.form.insurance._id;
              // delete this.form.third_insurance._id;
              // delete this.form.insurance._id;
              isClose = true;
            }
            let body = {
              token: this.$jwt.sign(this.form, this.$privateKey, {
                noTimestamp: true,
              }),
            };
            await this.$axios
              .post(`${this.$baseUrl}/sub_category/${subPath}`, body)
              .then(async (res) => {
                this.$alertSuccess({ title: res.status });
                this.form.third_insurance.count = 0;
                this.form.third_insurance.sub_category_id = res.result;
                this.form.insurance.sub_category_id = res.result;
                if (!isClose) {
                  // this.loading = true;
                  // let body = {
                  //   token: this.$jwt.sign(
                  //     { sub_category_id: res.result },
                  //     this.$privateKey,
                  //     { noTimestamp: true }
                  //   ),
                  // };
                  // await this.$axios
                  //   .post(`${this.$baseUrl}/insurance/get_insurance`, body)
                  //   .then(async (res) => {
                  //     let data = res.result;
                  //     let listFiltered = data;
                  //     if (this.filter.active != 'all') {
                  //       listFiltered = await data.filter((el) => {
                  //         return el.is_available == this.filter.active;
                  //       });
                  //     }
                  //     this.list.insurance = listFiltered;
                  //   })
                  //   .catch((err) => {
                  //     console.log('err', err);
                  //     this.$alertServerError({ title: err.error_message });
                  //   });
                  // this.loading = false;
                  // console.log(res.result);
                  // this.$router.push({
                  //   name: 'product-insurance-update',
                  //   params: { id: res.result },
                  // });
                    this.$router.push({
                    name: 'product-insurance',
                    params: { id: res.result },
                  });
                } else {
                  this.close();
                }
              })
              .catch((err) => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          }
          this.loading = false;
          this.$v.form.$reset();
        }
      );
    },
    edit(item) {
      this.$router.push({
        name: 'product-insurance-update',
        params: { id: item },
      });
    },
    close() {
      this.$router.push({ name: 'product-insurance' });
    },
    remove(item) {
      this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign(
                { insurance_id: item._id },
                this.$privateKey,
                { noTimestamp: true }
              ),
            };
            await this.$axios
              .post(`${this.$baseUrl}/insurance/delete_insurance`, body)
              .then((res) => {
                this.$alertSuccess({ title: res.status });
                this.getInsurance();
              })
              .catch((err) => {
                this.$alertServerError({ title: err.error_message });
              });
          }
        }
      );
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getInsurance();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    },
  },
  computed: {
    isUpdate() {
      return this.$route.params.id;
    },
  },
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
